import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import { copyToClipboard } from '../lib/Utils'
import { useToast } from '../sections/Toast'
import { useLocation } from '@reach/router'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, Link } from "gatsby"
import '../styles/Hero'
import '../styles/Prose'

import PDF from '../svg/PDF'
import PowerPoint from '../svg/PowerPoint'
import Video from '../svg/Video'
import Clipboard from '../svg/Clipboard'


const URL = 'https://caoneofficecdn.s3.us-east-1.amazonaws.com/docs/'

export default function MarketingPage() {
  const root = useClasses()
  const toast = useToast()
  const { origin } = useLocation()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/marketing/huawei-background.png" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const copyLink = function(link, prefix) {
      if (!prefix) prefix = URL
      copyToClipboard(prefix + link)
      toast.show('Link copied to clipboard', 'success')
  }

  return (
    <Layout full dark short>
      <SEO title='Marketing' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>

        <BackgroundImage className='hero-container h-120' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Marketing</div>
              <div className='hero-slanted'>Collateral</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>

            <div className="heading-separator"/>
            <h1 className="title">Marketing</h1>
            <h2 className="subtitle">Flyers, Presentations, Videos ...</h2>
            <div className="description">
              <p>
                This page contains our latest marketing collateral for the entire OneOffice suite. 
              </p>
              <p>
                We recommend you share links rather than actual files as we update these files regularly (<b>click on <Clipboard className='w-6 h-6 inline text-primary-dark'/> to copy link</b>).
              </p>
            </div>

            <div className='mt-30 mb-26'>
                <div className="subheading">Videos</div>
                <div className="heading">A video is a million words</div>
                <div className="description prose">
                  <p className='text-gray-600 mb-2'>
                    More videos to come (ERP, Communication, Remote Office) -- please contact us to know more.
                  </p>
                  <ul>
                    <li><a href={URL + "introduction.mp4"}>OneOffice - Introduction Video <Video className='inline w-6 h-6'/></a> - <Clipboard onClick={() => copyLink('introduction.mp4')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                    <li><a href={URL + "erp-services.mp4"}>OneOfficeERP - Services <Video className='inline w-6 h-6'/></a> - <Clipboard onClick={() => copyLink('erp-services.mp4')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                  </ul>
                </div>
            </div>


            <div className='mt-30'>
                <div className="subheading">Sales Tools</div>
                <div className="heading">Grow your Business with OneOffice</div>
                <div className="description prose">
                  <p className='text-gray-600'>
                    The Battle Cards have been carefully designed to give you all you need to get started. Ideal for blocking objections by customers and addressing competition.<br/>
                    The first half is focused on <strong>Productivity</strong> and the second on <strong>ERP</strong>. One-pager for OneOffice followed by one-pagers for each competing product.
                  </p>
                  <p className='quote'>
                    This information is competitive and cannot be shared with third-party.
                  </p>
                  <ul>
                      <li>
                        <a href={URL + "battle-cards.pdf"}>Battle Cards <PDF className='inline w-6 h-6'/></a> - 
                        <Clipboard onClick={() => copyLink('battle-cards.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                      </li>
                      <li>
                        <a href={URL + "ms365-enterprise.pdf"}>Vs. MS365 Enterprise <PDF className='inline w-6 h-6'/></a> - 
                        <Clipboard onClick={() => copyLink('ms365-enterprise.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                      </li>
                      <li>
                        <a href={URL + "ms365-basic.pdf"}>Vs. MS365 Basic <PDF className='inline w-6 h-6'/></a> - 
                        <Clipboard onClick={() => copyLink('ms365-basic.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                      </li>
                      <li>
                        <a href={URL + "sales-enablement.pdf"}>Sales Enablement Webinar <PDF className='inline w-6 h-6'/></a> - 
                        <Clipboard onClick={() => copyLink('sales-enablement.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                      </li>
                      <li>
                        <a href={URL + "security.pdf"}>Security <PDF className='inline w-6 h-6'/></a> - 
                        <Clipboard onClick={() => copyLink('security.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                      </li>
                      <li>
                        <a
                          href="https://marketplace.huaweicloud.com/intl/hidden/contents/8ae43766-220a-4f48-8b57-ed89049f2d43"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Huawei Marketplace Partner link
                        </a>
                        
                      </li>
                  </ul>
                </div>
            </div>

            <div className='mt-30'>
                <div className="subheading">Flyers</div>
                <div className="heading">Short'n'Sweet two-pagers</div>
                <div className="description prose">
                    <p className='text-gray-600 mb-2'>
                        Ideal for first intros to quickly cover the main features and spark interest.
                    </p>
                    <ul>
                        <li><a href={URL + "flyer-oo.pdf"}>Productivity Flyer <PDF className='inline w-6 h-6'/></a> - <Clipboard onClick={() => copyLink('flyer-oo.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                        <li><a href={URL + "flyer-erp.pdf"}>ERP Flyer <PDF className='inline w-6 h-6'/></a> - <Clipboard onClick={() => copyLink('flyer-erp.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                        <li><a href={URL + "flyer-k12.pdf"}>K12 Flyer <PDF className='inline w-6 h-6'/></a> - <Clipboard onClick={() => copyLink('flyer-k12.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                    </ul>
                </div>
            </div>

            <div className='mt-30'>
                <div className="subheading">Presentations</div>
                <div className="heading">Fun & Graphical Overview</div>
                <div className="description prose">
                    <p className='text-gray-600 mb-2'>
                        For a deep-dive into features, often with screenshots if you can't do a full demo on the spot.
                    </p>
                    <ul>
                        <li>
                          <a href={URL + "presentation-oo.pdf"}>Productivity Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-oo.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-oo.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-oo-lite.pdf"}>Productivity LITE Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-oo-lite.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-oo-lite.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-erp-modules.pdf"}>ERP Presentation - Modules <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-erp-modules.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-erp-modules.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-erp-verticals.pdf"}>ERP Presentation - Verticals <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-erp-verticals.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-erp-verticals.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-erp-hr.pdf"}>ERP Presentation - HR <PDF className='inline w-6 h-6'/></a> - 
                          <Clipboard onClick={() => copyLink('presentation-erp-hr.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-vc.pdf"}>VideoConference Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-vc.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-vc.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-projects.pdf"}>Projects Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-projects.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-projects.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        {/*
                        <li>
                          <a href={URL + "presentation-drive.pdf"}>Drive Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-drive.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-drive.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        */}
                        <li>
                          <a href={URL + "presentation-healthcare.pdf"}>Healthcare Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-healthcare.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-healthcare.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-highered.pdf"}>Higher Education Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-highered.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-highered.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                        <li>
                          <a href={URL + "presentation-k12.pdf"}>K12 Presentation <PDF className='inline w-6 h-6'/></a> - 
                          <a href={URL + "presentation-k12.pptx"}><PowerPoint className='inline w-6 h-6 mr-1'/></a>
                          <Clipboard onClick={() => copyLink('presentation-k12.pdf')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='mt-30 mb-26'>
                <div className="subheading">Links</div>
                <div className="heading">Key Marketing Pages</div>
                <div className="description prose">
                  <ul>
                      <li><Link to="/savings">Savings Calculator vs. MS365</Link> - <Clipboard onClick={() => copyLink('savings', origin + '/')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                      <li><Link to="/download">Applications Download</Link> - <Clipboard onClick={() => copyLink('download', origin + '/')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                      <li><a href="https://docs.oneoffice.ca">OneOffice Docs</a> - <Clipboard onClick={() => copyLink('', 'https://docs.oneoffice.ca')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                      <li><a href="https://erpdocs.oneoffice.ca">OneOfficeERP Docs</a> - <Clipboard onClick={() => copyLink('', 'https://erpdocs.oneoffice.ca')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                      <li><a href="https://editordocs.oneoffice.ca">OneOfficeEditor Docs</a> - <Clipboard onClick={() => copyLink('', 'https://editordocs.oneoffice.ca')} className='inline w-6 h-6 text-primary-dark hover:text-primary cursor-pointer'/> </li>
                  </ul>
                </div>
            </div>

        </div>


      </main>
    </Layout>
  )
}
